import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const anchors = this.element.querySelectorAll("a")

    anchors.forEach(anchor => {
      anchor.setAttribute("data-turbo-action", "replace")
    })
  }
}
